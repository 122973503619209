<template>
    <div class="product_page" v-if="productActiveIndex>=0">
        <div class="banner_wrap">
            <swiper ref="bannerSwiper" :options="bannerSwiperOptions" class="banner_swiper w_h_100 swiper-no-swiping">
                <swiper-slide class="banner_swiper_slide w_h_100 flex_center_center"
                    v-for="(item, index) of productObject.productImgs" :key="index">
                    <img class="w_h_100" :src="item" alt="">
                </swiper-slide>
            </swiper>
            <img src="@/assets/pc/img/prev_3.png" alt="" class="prev_btn absolute_t_center"
                @click="bannerSwiper.slidePrev()">
            <img src="@/assets/pc/img/next_3.png" alt="" class="next_btn absolute_t_center"
                @click="bannerSwiper.slideNext()">
        </div>
        <div class="product_detail_wrap">
            <div class="row_1">
                <div class="name_wrap">
                    <h2>DO IT NOW.</h2>
                    <h1>{{productObject.name_cn}}</h1>
                </div>
                <div class="buy_btn flex_center_center">
                    <img src="@/assets/pc/img/buy_tag.png" @click="handleBuyBtn" alt="">
                </div>
            </div>
            <div class="row_2">
                <div class="number_wrap flex_align_center">
                    <span class="number_text">数量:</span>
                    <div class="choose_content">
                        <span v-for="(item, index) of productObject.numberList" :key="index">{{item}}</span>
                    </div>
                </div>
                <div class="is_choose w_h_100 flex_center_center">可选购</div>
            </div>
            <div class="row_3 flex_center_center">中国大陆地区尊享顺丰免费配送</div>
            <div class="row_4">{{productObject.intro}}</div>
            <p class="idea_content flex_center_center">Integrate Into A Diverse Youth Culture</br>Create Fun And
                Interesting Peripheral
                Products</br>Help Young People Get Rid Of Shackles</br>Be The Most Authentic And Comfortable Self</p>
        </div>
        <div class="more_detail_wrap">
            <div class="more_title">更多详情</div>
            <div class="model_1 flex_col flex_align_center" v-if="productObject.styleModel == 1">
                <div class="poster_1_wrap flex_center_center">
                    <img v-lazy="productObject.posterImgs[0]" alt="" class="poster_1 w_h_100">
                </div>
                <div class="copywriting">{{productObject.copywriting}}</div>
                <img v-lazy="productObject.posterImgs[1]" alt="" class="poster_2">
                <img v-lazy="productObject.posterImgs[2]" alt="" class="poster_3">
                <div class="pack_wrap flex_justify_between">
                    <img v-lazy="productObject.posterImgs[3]" alt="" class="poster_4">
                    <div class="pack_info">
                        <h3>Doitforchange</h3>
                        <p>经典信封包装</br>外出携带不尴尬</br>小解解都爱的套套</p>
                        <img v-lazy="productObject.posterImgs[4]" alt="" class="poster_5">
                    </div>
                </div>
                <div class="complimentary_card_wrap">
                    <div class="title_wrap flex_align_center flex_justify_between">
                        <span class="en">Complimentary Card</span>
                        <span class="cn">附赠卡片</span>
                        <img class="absolute_l_b" src="@/assets/pc/img/tag_6.png" alt="">
                    </div>
                    <div class="content_wrap flex_justify_between">
                        <div class="text_wrap flex_col flex_align_center">
                            <h2>爱的zi式</h2>
                            <img src="@/assets/pc/img/tag_7.png" alt="">
                            <p>每个信封都暗藏惊喜</br>多名设计师联合创作</p>
                        </div>
                        <div class="card_wrap">
                            <img v-for="(item, index) of productObject.cardImgs" v-lazy="item" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="model_2 flex_col flex_align_center" v-if="productObject.styleModel == 2">
                <div class="info_wrap">
                    <h1 class="en">{{productObject.name_en}}</h1>
                    <div class="name_wrap flex_align_end flex_justify_between">
                        <h2>{{productObject.name_cn}}</h2>
                        <span>{{productObject.copywriting}}</span>
                    </div>
                </div>
                <div class="poster_1_wrap flex_center_center">
                    <img v-lazy="productObject.posterImgs[0]" alt="" class="poster_1 w_h_100">
                </div>
                <div class="wrapper_1 flex_justify_between">
                    <div class="poster_text_wrap flex_col">
                        <div class="cn">{{productObject.posterCn}}</div>
                        <div class="en">{{productObject.posterEn}}<img class="absolute_l_b" src="@/assets/pc/img/tag_8.png" alt=""></div>
                        <div class="remarks">{{productObject.posterRemarks}}</div>
                        <img v-lazy="productObject.posterImgs[1]" alt="" class="poster_2">
                    </div>
                    <img v-lazy="productObject.posterImgs[2]" alt="" class="poster_3">
                </div>
                <div class="wrapper_2 flex_justify_between">
                    <img v-lazy="productObject.posterImgs[3]" alt="" class="poster_4">
                    <div class="poster_text_wrap flex_col">
                        <P>{{productObject.posterText1}}</P>
                        <img v-lazy="productObject.posterImgs[4]" alt="" class="poster_5">
                    </div>
                    <img src="@/assets/mobile/img/tag_7.png" alt="" class="tag_7 absolute_r_t">
                </div>
                <img v-lazy="productObject.posterImgs[5]" alt="" class="poster_7">
            </div>
        </div>
        <m-custom-foot></m-custom-foot>
    </div>
</template>

<script>
    import 'swiper/dist/css/swiper.css'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'
    import { pcCosBaseUrl, mobileCosBaseUrl, baseCosBaseUrl } from '@/config/evn.js'
    export default {
        name: 'product',
        data() {
            return {
                productActiveIndex: -1,
                bannerSwiperOptions: {
                    effect: 'fade',
                    slidesPerView: '1',
                    loop: true,
                    autoplay: {
                        disableOnInteraction: false
                    }
                },
                allProductsList: [
                    {
                        id: 1,
                        address: 'https://shop.m.jd.com/mshop/SearchList?venderId=1000395224&vendorId=1000395224&shopId=1000395224&shopCategoryId=18002803&sceneval=2&jxsid=16393835683466385243.html',
                        URLScheme: '',
                        styleModel: 1,
                        name_cn: '螺纹颗粒款',
                        name_en: 'Thread Particle',
                        copywriting: '每一个颗粒都懂你的点\n让每一次的碰撞都有回应',
                        numberList: [
                            '3只装'
                        ],
                        isChoose: true,
                        intro: '乐度天然胶乳橡胶避孕套采用天然胶乳制成，常规通用薄膜套状物，开口端为卷边，头部有储精囊，加注硅油后用不透明铝膜包装而成。本产品主要供男性用于避孕和预防性传播疾病。',
                        productImgs: [
                            mobileCosBaseUrl + 'product_01_imgs_1.png',
                            mobileCosBaseUrl + 'product_01_imgs_2.png',
                            mobileCosBaseUrl + 'product_01_imgs_3.png'
                        ],
                        posterImgs: [
                            mobileCosBaseUrl + 'product_01_poster_1.png',
                            mobileCosBaseUrl + 'product_01_poster_2.png',
                            mobileCosBaseUrl + 'product_01_poster_3.png',
                            mobileCosBaseUrl + 'product_01_poster_4.png',
                            mobileCosBaseUrl + 'product_01_poster_5.png'
                        ],
                        cardImgs: [
                            pcCosBaseUrl + 'complimentary_card_05.png',
                            pcCosBaseUrl + 'complimentary_card_06.png',
                            pcCosBaseUrl + 'complimentary_card_07.png',
                            pcCosBaseUrl + 'complimentary_card_08.png'
                        ]
                    }, {
                        id: 2,
                        address: 'https://shop.m.jd.com/mshop/SearchList?venderId=1000395224&vendorId=1000395224&shopId=1000395224&shopCategoryId=18002801&sceneval=2&jxsid=16393835683466385243',
                        URLScheme: '',
                        styleModel: 1,
                        name_cn: '超薄款',
                        name_en: 'Ultra Thin',
                        copywriting: '似有若无带若无物\n薄度导热 给你带来真实触感',
                        numberList: [
                            '9只装'
                        ],
                        isChoose: true,
                        intro: '乐度天然胶乳橡胶避孕套采用天然胶乳制成，常规通用薄膜套状物，开口端为卷边，头部有储精囊，加注硅油后用不透明铝膜包装而成。本产品主要供男性用于避孕和预防性传播疾病。',
                        productImgs: [
                            mobileCosBaseUrl + 'product_02_imgs_1.png',
                            mobileCosBaseUrl + 'product_02_imgs_2.png',
                            mobileCosBaseUrl + 'product_02_imgs_3.png'
                        ],
                        posterImgs: [
                            mobileCosBaseUrl + 'product_02_poster_1.png',
                            mobileCosBaseUrl + 'product_02_poster_2.png',
                            mobileCosBaseUrl + 'product_02_poster_3.png',
                            mobileCosBaseUrl + 'product_02_poster_4.png',
                            mobileCosBaseUrl + 'product_02_poster_5.png'
                        ],
                        cardImgs: [
                            pcCosBaseUrl + 'complimentary_card_09.png',
                            pcCosBaseUrl + 'complimentary_card_10.png',
                            pcCosBaseUrl + 'complimentary_card_11.png',
                            pcCosBaseUrl + 'complimentary_card_12.png'
                        ]
                    }, {
                        id: 3,
                        address: 'https://shop.m.jd.com/mshop/SearchList?venderId=1000395224&vendorId=1000395224&shopId=1000395224&shopCategoryId=18002799&sceneval=2&jxsid=16393835683466385243',
                        URLScheme: '',
                        styleModel: 1,
                        name_cn: '玻尿酸款',
                        name_en: 'Hyaluronic Acid',
                        copywriting: '添加水溶性玻尿酸\n超水润、不油腻、易清洗',
                        numberList: [
                            '3只装',
                            '6只装'
                        ],
                        isChoose: true,
                        intro: '乐度天然胶乳橡胶避孕套采用天然胶乳制成，常规通用薄膜套状物，开口端为卷边，头部有储精囊，加注硅油后用不透明铝膜包装而成。本产品主要供男性用于避孕和预防性传播疾病。',
                        productImgs: [
                            mobileCosBaseUrl + 'product_03_imgs_1.png',
                            mobileCosBaseUrl + 'product_03_imgs_2.png',
                            mobileCosBaseUrl + 'product_03_imgs_3.png'
                        ],
                        posterImgs: [
                            mobileCosBaseUrl + 'product_03_poster_1.png',
                            mobileCosBaseUrl + 'product_03_poster_2.png',
                            mobileCosBaseUrl + 'product_03_poster_3.png',
                            mobileCosBaseUrl + 'product_03_poster_4.png',
                            mobileCosBaseUrl + 'product_03_poster_5.png'
                        ],
                        cardImgs: [
                            pcCosBaseUrl + 'complimentary_card_05.png',
                            pcCosBaseUrl + 'complimentary_card_10.png',
                            pcCosBaseUrl + 'complimentary_card_11.png',
                            pcCosBaseUrl + 'complimentary_card_13.png'
                        ]
                    }, {
                        id: 4,
                        address: 'https://shop.m.jd.com/mshop/SearchList?venderId=1000395224&vendorId=1000395224&shopId=1000395224&shopCategoryId=18002800&sceneval=2&jxsid=16393835683466385243',
                        URLScheme: '',
                        styleModel: 1,
                        name_cn: '超润超薄款',
                        name_en: 'Super Moist Ultra-Thin',
                        copywriting: '超薄+超润=乐趣200%',
                        numberList: [
                            '3只装',
                            '6只装',
                            '9只装'
                        ],
                        isChoose: true,
                        intro: '乐度天然胶乳橡胶避孕套采用天然胶乳制成，常规通用薄膜套状物，开口端为卷边，头部有储精囊，加注硅油后用不透明铝膜包装而成。本产品主要供男性用于避孕和预防性传播疾病。',
                        productImgs: [
                            mobileCosBaseUrl + 'product_04_imgs_1.png',
                            mobileCosBaseUrl + 'product_04_imgs_2.png',
                            mobileCosBaseUrl + 'product_04_imgs_3.png'
                        ],
                        posterImgs: [
                            mobileCosBaseUrl + 'product_04_poster_1.png',
                            mobileCosBaseUrl + 'product_04_poster_2.png',
                            mobileCosBaseUrl + 'product_04_poster_3.png',
                            mobileCosBaseUrl + 'product_04_poster_4.png',
                            mobileCosBaseUrl + 'product_04_poster_5.png'
                        ],
                        cardImgs: [
                            pcCosBaseUrl + 'complimentary_card_12.png',
                            pcCosBaseUrl + 'complimentary_card_07.png',
                            pcCosBaseUrl + 'complimentary_card_03.png',
                            pcCosBaseUrl + 'complimentary_card_02.png'
                        ]
                    }, {
                        id: 5,
                        address: 'https://shop.m.jd.com/mshop/SearchList?venderId=1000395224&vendorId=1000395224&shopId=1000395224&shopCategoryId=18002802&sceneval=2&jxsid=16393835683466385243',
                        URLScheme: '',
                        styleModel: 1,
                        name_cn: '持久延迟款',
                        name_en: 'Long Delay Payment',
                        copywriting: '区别于传统加厚物理加时套\n只在敏感部位加厚 套身依旧薄至0.03',
                        numberList: [
                            '3只装'
                        ],
                        isChoose: true,
                        intro: '乐度天然胶乳橡胶避孕套采用天然胶乳制成，常规通用薄膜套状物，开口端为卷边，头部有储精囊，加注硅油后用不透明铝膜包装而成。本产品主要供男性用于避孕和预防性传播疾病。',
                        productImgs: [
                            mobileCosBaseUrl + 'product_05_imgs_1.png',
                            mobileCosBaseUrl + 'product_05_imgs_2.png',
                            mobileCosBaseUrl + 'product_05_imgs_3.png'
                        ],
                        posterImgs: [
                            mobileCosBaseUrl + 'product_05_poster_1.png',
                            mobileCosBaseUrl + 'product_05_poster_2.png',
                            mobileCosBaseUrl + 'product_05_poster_3.png',
                            mobileCosBaseUrl + 'product_05_poster_4.png',
                            mobileCosBaseUrl + 'product_05_poster_5.png'
                        ],
                        cardImgs: [
                            pcCosBaseUrl + 'complimentary_card_01.png',
                            pcCosBaseUrl + 'complimentary_card_02.png',
                            pcCosBaseUrl + 'complimentary_card_03.png',
                            pcCosBaseUrl + 'complimentary_card_04.png'
                        ]
                    }, {
                        id: 6,
                        address: '',
                        URLScheme: 'weixin://dl/business/?t=wdTqBhbqfqr',
                        styleModel: 2,
                        name_cn: '润滑剂',
                        name_en: 'Lubricant',
                        copywriting: '“ 让你感受不一样的张弛有度 ”',
                        posterCn: 'Ci溜Ci溜',
                        posterEn: 'Feel\nDifferent Happiness',
                        posterRemarks: 'Ice feeling / Heat sensation /',
                        posterText1: '萃取植物成分\n误入空中也不慌',
                        numberList: [
                            '1盒装'
                        ],
                        isChoose: true,
                        intro: '解套致力于打造好玩有趣、年轻时尚的两性生活品牌。面对现实的困惑，解套倡导积极乐观充满激情的生活态度，期待每一次勇敢尝试后带来的多种改变。',
                        productImgs: [
                            mobileCosBaseUrl + 'product_06_imgs_1.png',
                            mobileCosBaseUrl + 'product_06_imgs_2.png',
                            mobileCosBaseUrl + 'product_06_imgs_3.png'
                        ],
                        posterImgs: [
                            mobileCosBaseUrl + 'product_06_poster_1.png',
                            mobileCosBaseUrl + 'product_06_poster_2.png',
                            mobileCosBaseUrl + 'product_06_poster_3.png',
                            mobileCosBaseUrl + 'product_06_poster_4.png',
                            mobileCosBaseUrl + 'product_06_poster_5.png',
                            mobileCosBaseUrl + 'product_06_poster_6.png'
                        ]
                    }, {
                        id: 7,
                        address: '',
                        URLScheme: 'weixin://dl/business/?t=As0Fd1J8jxi',
                        styleModel: 2,
                        name_cn: '钥匙扣',
                        name_en: 'Key Buckle',
                        copywriting: '“ 穿过你的心门 只差一个时机 ”',
                        posterCn: '扣扣相穿',
                        posterEn: 'KEY CHAIN\nDecorate everything',
                        posterRemarks: 'bag / key / zip /',
                        posterText1: '尼龙加刺绣工艺\n怎么拉扯都完好无损',
                        numberList: [
                            '1个装'
                        ],
                        isChoose: true,
                        intro: '解套致力于打造好玩有趣、年轻时尚的两性生活品牌。面对现实的困惑，解套倡导积极乐观充满激情的生活态度，期待每一次勇敢尝试后带来的多种改变。',
                        specificList: [
                            '材质：亚克力\刺绣',
                            '形状：如图',
                            '颜色：蓝色',
                            '包装：单个入透明小袋',
                            '用途：想挂哪就挂哪',
                            '对以上成分过敏者请勿使用'
                        ],
                        specificImg: mobileCosBaseUrl + 'product_07_specific.png',
                        coverImg: mobileCosBaseUrl + 'product_07_cover.png',
                        productImgs: [
                            mobileCosBaseUrl + 'product_07_imgs_1.png',
                            mobileCosBaseUrl + 'product_07_imgs_2.png',
                            mobileCosBaseUrl + 'product_07_imgs_3.png'
                        ],
                        posterImgs: [
                            mobileCosBaseUrl + 'product_07_poster_1.png',
                            mobileCosBaseUrl + 'product_07_poster_2.png',
                            mobileCosBaseUrl + 'product_07_poster_3.png',
                            mobileCosBaseUrl + 'product_07_poster_4.png',
                            mobileCosBaseUrl + 'product_07_poster_5.png',
                            mobileCosBaseUrl + 'product_07_poster_6.png'
                        ]
                    }, {
                        id: 8,
                        address: '',
                        URLScheme: 'weixin://dl/business/?t=2mMNVZsL55t',
                        styleModel: 2,
                        name_cn: '丝巾',
                        name_en: 'Silk Scarf',
                        copywriting: '“ 穿过你的心门 只差一个时机”',
                        posterCn: '一丝不挂',
                        posterEn: 'These\nthings on you',
                        posterRemarks: 'Tuba / trumpet /',
                        posterText1: '顺滑的不止是丝巾\n还有少女的身体',
                        numberList: [
                            '850mm'
                        ],
                        isChoose: true,
                        intro: '解套致力于打造好玩有趣、年轻时尚的两性生活品牌。面对现实的困惑，解套倡导积极乐观充满激情的生活态度，期待每一次勇敢尝试后带来的多种改变。',
                        productImgs: [
                            mobileCosBaseUrl + 'product_08_imgs_1.png',
                            mobileCosBaseUrl + 'product_08_imgs_2.png',
                            mobileCosBaseUrl + 'product_08_imgs_3.png'
                        ],
                        posterImgs: [
                            mobileCosBaseUrl + 'product_08_poster_1.png',
                            mobileCosBaseUrl + 'product_08_poster_2.png',
                            mobileCosBaseUrl + 'product_08_poster_3.png',
                            mobileCosBaseUrl + 'product_08_poster_4.png',
                            mobileCosBaseUrl + 'product_08_poster_5.png',
                            mobileCosBaseUrl + 'product_08_poster_6.png'
                        ]
                    }, {
                        id: 9,
                        address: '',
                        URLScheme: 'weixin://dl/business/?t=eem5ha7idEg',
                        styleModel: 2,
                        name_cn: '胸贴',
                        name_en: 'Chest Paste',
                        copywriting: '“ 穿过你的心门 只差一个时机”',
                        posterCn: 'XX胸贴',
                        posterEn: 'These\nthings on you',
                        posterRemarks: 'Tuba / trumpet /',
                        posterText1: '少女的躯体犹如春水丝滑\n双手握住两座礁石 心甘情愿\n淹死在你怀里',
                        numberList: [
                            '8对装'
                        ],
                        isChoose: true,
                        intro: '解套致力于打造好玩有趣、年轻时尚的两性生活品牌。面对现实的困惑，解套倡导积极乐观充满激情的生活态度，期待每一次勇敢尝试后带来的多种改变。',
                        productImgs: [
                            mobileCosBaseUrl + 'product_09_imgs_1.png',
                            mobileCosBaseUrl + 'product_09_imgs_2.png',
                            mobileCosBaseUrl + 'product_09_imgs_3.png'
                        ],
                        posterImgs: [
                            mobileCosBaseUrl + 'product_09_poster_1.png',
                            mobileCosBaseUrl + 'product_09_poster_2.png',
                            mobileCosBaseUrl + 'product_09_poster_3.png',
                            mobileCosBaseUrl + 'product_09_poster_4.png',
                            mobileCosBaseUrl + 'product_09_poster_5.png',
                            mobileCosBaseUrl + 'product_09_poster_6.png'
                        ]
                    }, {
                        id: 10,
                        address: '',
                        URLScheme: 'weixin://dl/business/?t=fvYHwfKMA7n',
                        styleModel: 2,
                        name_cn: '福袋',
                        name_en: 'Blessing Bag',
                        copywriting: '“ 穿过你的心门 只差一个时机”',
                        posterCn: '福满多多',
                        posterEn: 'These\nthings on you',
                        posterRemarks: 'Tuba / trumpet /',
                        posterText1: '装你所想无尽可能\n我们期待变化却又畏惧行动\n我们期待改变却又害怕未知',
                        numberList: [
                            '1只装'
                        ],
                        isChoose: true,
                        intro: '解套致力于打造好玩有趣、年轻时尚的两性生活品牌。面对现实的困惑，解套倡导积极乐观充满激情的生活态度，期待每一次勇敢尝试后带来的多种改变。',
                        productImgs: [
                            mobileCosBaseUrl + 'product_10_imgs_1.png',
                            mobileCosBaseUrl + 'product_10_imgs_2.png',
                            mobileCosBaseUrl + 'product_10_imgs_3.png'
                        ],
                        posterImgs: [
                            mobileCosBaseUrl + 'product_10_poster_1.png',
                            mobileCosBaseUrl + 'product_10_poster_2.png',
                            mobileCosBaseUrl + 'product_10_poster_3.png',
                            mobileCosBaseUrl + 'product_10_poster_4.png',
                            mobileCosBaseUrl + 'product_10_poster_5.png',
                            mobileCosBaseUrl + 'product_10_poster_6.png'
                        ]
                    }
                ],
            }
        },
        created() {
            let id = this.$route.query.id
            if (id) {
                this.initById(JSON.parse(id))
            }
        },
        computed: {
            productObject() {
                let productActiveIndex = this.productActiveIndex
                if (productActiveIndex < 0) return undefined
                return this.allProductsList[productActiveIndex]
            },
            bannerSwiper() {
                return this.$refs.bannerSwiper.swiper
            }
        },
        methods: {
            initById(id) {
                this.productActiveIndex = -1
                let allProductsList = this.allProductsList
                let maxLen = allProductsList.length
                for (let i = 0; i < maxLen; i++) {
                    if (allProductsList[i].id === id) {
                        this.productActiveIndex = i
                        break
                    }
                }
            },
            handleBuyBtn() {
                let address = this.productObject.address
                if (address) return this.$windowOpen(address)
                this.$openWXWApplet(this.productObject.URLScheme)
            }
        },
        components: {
            swiper,
            swiperSlide
        }
    }
</script>

<style lang="scss" scoped>
    .product_page {
        .banner_wrap {
            position: relative;
            height: 917px;

            .prev_btn,
            .next_btn {
                z-index: 1;
                width: 72px;
                height: 68px;
            }

            .prev_btn {
                left: 30px;
            }

            .next_btn {
                right: 30px;
            }
        }

        .product_detail_wrap {

            .row_1,
            .row_2,
            .row_3,
            .row_4 {
                display: flex;
                border-bottom: 2PX solid #2C2C2C;
            }

            .row_1 {
                width: 100%;
                height: 148px;

                .name_wrap {
                    width: 310px;
                    text-align: left;
                    border-right: 2PX solid #2C2C2C;
                    padding: 34px 0 0 60px;

                    h2 {
                        line-height: 29px;
                        font-family: HurmeGeometricSans3-SemiBold;
                        font-size: 24px;
                        color: #BFBFBF;
                    }

                    h1 {
                        font-family: SourceHanSansCN-Bold;
                        font-size: 38px;
                        margin-top: 8px;
                    }
                }

                .buy_btn {
                    flex: 1;

                    img {
                        width: 198px;
                        height: 60px;
                    }
                }
            }

            .row_2 {
                height: 146px;

                .number_wrap {
                    flex-shrink: 0;
                    width: 500px;
                    border-right: 2PX solid #2C2C2C;
                    padding-left: 40px;

                    .number_text {
                        font-family: SourceHanSansCN-Bold;
                        font-size: 30px;
                    }

                    .choose_content {
                        width: 360px;
                        display: flex;
                        font-size: 24px;
                        overflow: hidden;

                        span {
                            width: 120px;
                            line-height: 42px;
                            border: 2px solid #111;
                            border-radius: 38px;
                            margin-left: 30px;
                        }
                    }
                }

                .is_choose {
                    font-family: SourceHanSansCN-Bold;
                    font-size: 30px;
                    color: #2C2C2C;
                }
            }

            .row_3 {
                height: 116px;
                font-family: SourceHanSansCN-Regular;
                font-size: 26px;
                color: #888888;
            }

            .row_4 {
                line-height: 60px;
                font-family: SourceHanSansCN-Regular;
                font-size: 24px;
                text-align: left;
                padding: 12px 32px;
            }

            .idea_content {
                height: 400px;
                font-family: HurmeGeometricSans3-SemiBold;
                font-size: 26px;
                line-height: 60px;
                color: #0000FF;
                background-color: #F6F6F6;
            }
        }

        .more_detail_wrap {
            white-space: pre-wrap;
            .more_title {
                position: relative;
                font-family: SourceHanSansCN-Bold;
                font-size: 36px;
                letter-spacing: 2px;
                margin: 60px 0;

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 260px;
                    height: 2px;
                    background-color: #111111;
                }

                &::before {
                    left: 24px;
                }

                &::after {
                    right: 24px;
                }
            }

            .model_1 {

                .poster_1_wrap {
                    width: 476px;
                    height: 682px;
                    background-color: #F6F6F6;
                }

                .copywriting {
                    line-height: 48px;
                    font-size: 24px;
                    margin: 18px 0 78px;
                }

                .poster_2 {
                    width: 570px;
                    height: 78px;
                }

                .poster_3 {
                    width: 702px;
                    height: 465px;
                    margin: 30px 0 90px;
                }

                .pack_wrap {
                    width: 100%;
                    padding: 0 24px;

                    .poster_4 {
                        width: 340px;
                        height: 508px;
                    }

                    .pack_info {
                        width: 293px;
                        text-align: left;
                        padding-top: 47px;

                        h3 {
                            line-height: 34px;
                            font-family: HurmeGeometricSans3-Black;
                            font-size: 28px;
                            letter-spacing: 1.5px;
                        }

                        p {
                            line-height: 40px;
                            font-size: 18px;
                            margin: 17px 0 7px;
                        }

                        .poster_5 {
                            width: 100%;
                            height: 293px;
                        }
                    }
                }

                .complimentary_card_wrap {
                    width: 100%;
                    padding: 0 24px 90px;
                    margin-top: 82px;

                    .title_wrap {
                        position: relative;
                        width: 100;
                        height: 50px;
                        padding-right: 18px;

                        .en {
                            line-height: 34px;
                            font-family: HurmeGeometricSans3-Bold;
                            font-size: 28px;
                        }

                        .cn {
                            font-size: 18px;
                            color: #2C2C2C;
                            letter-spacing: 2px;
                        }

                        img {
                            width: 100%;
                            height: 15.7px;
                        }
                    }

                    .content_wrap {
                        width: 100%;
                        height: 398px;
                        margin-top: 60px;

                        .text_wrap {
                            width: 286px;
                            height: 100%;

                            h2 {
                                font-family: SourceHanSansCN-Bold;
                                font-size: 28px;
                                letter-spacing: 2px;
                            }

                            img {
                                width: 50px;
                                height: 156px;
                                margin: 68px 0 55px;
                            }

                            p {
                                line-height: 54px;
                                font-size: 24px;
                                color: #2C2C2C;
                                letter-spacing: 2px;
                            }
                        }

                        .card_wrap {
                            width: 398px;
                            height: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            align-content: space-between;

                            img {
                                width: 192px;
                                height: 192px;
                            }
                        }
                    }
                }
            }
            .model_2 {
                text-align: left;
                padding: 0 24px;
                .info_wrap {
                    width: 100%;
                    h1 {
                        line-height: 37px;
                        font-family: HurmeGeometricSans3-Bold;
                        font-size: 30px;
                    }
                    .name_wrap {
                        width: 100%;
                        margin-top: 16px;
                        h2 {
                            font-size: 30px;
                        }
                        span {
                            font-size: 24px;
                        }
                    }
                }
                .poster_1_wrap {
                    width: 100%;
                    height: 490px;
                    background-color: #F6F6F6;
                    margin: 30px 0 60px;
                }
                .wrapper_1 {
                    width: 100%;
                    height: 529px;
                    .poster_text_wrap {
                        width: 283px;
                        .cn {
                            font-family: SourceHanSansCN-Bold;
                            font-size: 26px;
                        }
                        .en {
                            position: relative;
                            width: 240px;
                            line-height: 30px;
                            font-family: HurmeGeometricSans3-SemiBold;
                            font-size: 18px;
                            margin: 10px 0 18px;
                            img {
                                width: 100%;
                                height: 11px;
                            }
                        }
                        .remarks {
                            line-height: 22px;
                            font-family: HurmeGeometricSans3-Light;
                            font-size: 18px;
                        }
                        .poster_2 {
                            width: 100%;
                            height: 367px;
                            margin-top: auto;
                        }
                    }
                    .poster_3 {
                        width: 351px;
                        height: 100%;
                    }
                }
                .wrapper_2 {
                    position: relative;
                    width: 100%;
                    height: 526px;
                    margin: 60px 0;
                    .poster_4 {
                        width: 351px;
                        height: 100%;
                    }
                    .poster_text_wrap {
                        width: 323px;
                        height: 100%;
                        p {
                            line-height: 48px;
                            font-size: 24px;
                        }
                        .poster_5 {
                            width: 100%;
                            height: 418px;
                            margin-top: auto;
                        }
                    }
                    .tag_7 {
                        width: 20px;
                        height: 72px;
                    }
                }
                .poster_7 {
                    width: 100%;
                    margin-bottom: 60px;
                }
            }
        }
    }
</style>